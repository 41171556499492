// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';
import FORM_OPERATION_BODY from '@/graphql/shared/form-operation-body';

const QUERY = gql`
  query QuickAddForm(
    $navigationSlug: String!,
    $dossierTypeSlug: String!
  ) {
    quickAddForm: dossierTypeListQuickAddForm(
      navigationSlug: $navigationSlug,
      dossierTypeSlug: $dossierTypeSlug
    ) {
      ${FORM_OPERATION_BODY}
    }
  }
`;

const MUTATION = gql`
  mutation QuickAddForm(
    $navigationSlug: String!,
    $dossierTypeSlug: String!,
    $data: JSON
  ) {
    quickAddForm: dossierTypeListQuickAddForm(
      navigationSlug: $navigationSlug,
      dossierTypeSlug: $dossierTypeSlug,
      data: $data
    ) {
      ${FORM_OPERATION_BODY}
    }
  }
`;

export { QUERY, MUTATION };
